import logo from './logo.png';
import './App.css';
import React, { useEffect, useState } from 'react';

function App() {
  const [platform, setPlatform] = useState('unknown');

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setPlatform('ios');
    } else if (/android/.test(userAgent)) {
      setPlatform('android');
    } else {
      setPlatform('unknown');
    }
  }, []);

  // const getAppStoreLink = (platform) => {
  //   if (platform === 'ios') {
  //     return 'https://apps.apple.com/app/my-app';
  //   } else if (platform === 'android') {
  //     return 'https://play.google.com/store/apps/details?id=com.myapp';
  //   } else {
  //     return '';
  //   }
  // };


  console.log(platform)
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div
          style={{
            marginTop:80,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {(platform === 'ios' || platform === 'unknown') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <img
                style={{ width: 150, height: 150 }}
                src="https://purepng.com/public/uploads/large/purepng.com-appstore-iconsymbolsiconsapple-iosiosios-8-iconsios-8-721522596009rinhz.png"
              ></img>
              <a
                className="App-link"
                href={
                  'https://apps.apple.com/us/app/hennies/id6448656621'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Download on the App Store
              </a>
            </div>
          )}
          {(platform === 'android' || platform === 'unknown') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                style={{ width: 150, height: 150 }}
                src="https://logos-download.com/wp-content/uploads/2018/08/Google_Play_logo_store.png"
              ></img>
              <a
                className="App-link"
                href={
                  'https://play.google.com/store/apps/details?id=com.hennies&pli=1'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Get it on Google Play
              </a>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
